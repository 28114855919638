import React, { Component } from "react";
import { connect } from "react-redux";
import * as auth from "../../actions";
import logoImage from "../../assets/images/logo.svg";
import logoRedImage from "../../assets/images/logo-red.svg";
import menuImage from "../../assets/images/menu.svg";
import { Nav, NavDropdown } from "react-bootstrap";
import Login from "../../modules/auth/login";
import ModalComponent from "../modal/modal.component";
import Registration from "../../modules/auth/registration";
import { Link, withRouter } from "react-router-dom";
import { SpinnerContext } from "../spinner/spinner.component";
import { chatActions } from "../../actions";
import { bindActionCreators } from "redux";
import locationImage from "../../assets/images/location-new.svg";
import locationDisableImage from "../../assets/images/location-disable.svg";
import locationModalImage from "../../assets/images/with-location.png";
import locationDisableModalImage from "../../assets/images/without-location.png";
import { checkHasNavigationPermission } from "../../utils";
class HeaderComponent extends Component {
  static contextType = SpinnerContext;

  constructor(props) {
    super(props);
    this.state = {
      signInModalShow: false,
      registrationModalShow: false,
      scrollTop: 0,
      isSignIn: false,
      hasPermission: null,
      open: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.unlisten = this.props.history.listen((location, action) => {
      this.context.setStickyHeader(true);
      this.context.setStickyFooter(true);
    });
    this.getLocationPermission();

    if (this.props.hasAuthToken) {
      this.props.fetchChats();
    }

    // Dynamically inject Google Analytics script
    // const script1 = document.createElement("script");
    // script1.async = true;
    // script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8K8WEE72YS";

    // const script2 = document.createElement("script");
    // script2.innerHTML = `
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() { dataLayer.push(arguments); }
    //   gtag('js', new Date());
    //   gtag('config', 'G-8K8WEE72YS');
    // `;

    // document.head.appendChild(script1);
    // document.head.appendChild(script2);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      (this.props.hasAuthToken || this.props.forgotPasswordSuccess)
    ) {
      this.toggleSignInModal(false);
    }
    const isSignIn = new URLSearchParams(this.props.location.search).get(
      "isSignIn"
    );
    if (isSignIn === "true" && !this.state.signInModalShow) {
      this.setState({ isSignIn: true });
      this.toggleSignInModal(true);
    }
    if (this.props.hasAuthToken && !prevProps.hasAuthToken) {
      this.props.fetchChats();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();

    // // Cleanup scripts on unmount
    // const script1 = document.querySelector(
    //   `script[src="https://www.googletagmanager.com/gtag/js?id=G-8K8WEE72YS"]`
    // );
    // const script2 = document.querySelector(
    //   `script:contains('gtag('config', 'G-8K8WEE72YS')')`
    // );

    // if (script1) document.head.removeChild(script1);
    // if (script2) document.head.removeChild(script2);
  }

  handleScroll = () => {
    this.setState({
      scrollTop: Math.ceil(window.scrollY),
    });
  };

  toggleSignInModal = (value) => {
    this.setState({ signInModalShow: value });
    if (value) this.toggleRegistrationModal(false);
    else {
      const isSignIn = new URLSearchParams(this.props.location.search).get(
        "isSignIn"
      );
      if (isSignIn) this.props.history.push("/");
    }
    if (this.state.isSignIn || this.context.isShowLogin()) {
      this.setState({ isSignIn: false });
      this.context.showLogin(false);
    }
  };
  toggleRegistrationModal = (value) => {
    this.setState({ registrationModalShow: value });
    if (value) this.toggleSignInModal(false);
  };

  onLogout() {
    this.props.logout();
  }
  unReadMessageCount = () => {
    return this.props.chats.reduce((acc, cur) => acc + cur.unreadCount, 0);
  };

  getLocationPermission = () => {
    checkHasNavigationPermission()
      .then((hasPermission) => {
        this.setState({ hasLocationPermission: hasPermission });
      })
      .catch(() => {
        console.error("Error checking location permission");
      });
  };

  render() {
    const isShown = localStorage.getItem("isShown");
    const { hasLocationPermission } = this.state;

    return (
      <>
        {/* ==========donot remove this commented code================ */}
        {/* {this?.props?.isShow &&
        isShown !== "false" &&
        this.props.user?.role !== "regular" &&
        this.props.history?.location.pathname === "/" ? (
          <section className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="top-bar-content d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <h2>
                      Now Accepting All Levels of Care Facilities! Sign Up Now
                      and Receive a Limited Time Presale Offer!
                    </h2>
                    <button
                      className="btn btn-outline radius-20"
                      onClick={() => this.props.history.push("/profile")}
                    >
                      Sign up now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )} */}
        {/* =========================upto here donot remove =========================== */}
        <header
          /*className={this.state.scrollTop > 50 ? "taste-header scrolled" : "taste-header"}>*/ className={`taste-header ${
            this.context.isStickyHeader()
              ? "scrolled-white"
              : this.state.scrollTop > 50
              ? "scrolled"
              : this.props.history?.location?.state?.state === "sober"
              ? "header-white"
              : "scrolled-white"
          }`}
        >
          <nav className="navbar navbar-expand-lg navbar-light bg-transparent fixed-top">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                  className="logo-white"
                  src={logoImage}
                  alt="Taste Recovery logo"
                />
                <img
                  className="logo-red"
                  src={logoRedImage}
                  alt="Taste Recovery logo"
                />
              </Link>
              <div className=" navbar-collapse" id="navbarTogglerDemo01">
                <Nav className="navbar-nav ml-auto mt-2 mt-lg-0" as="ul">
                  {
                    <Nav.Item>
                      <Nav.Link
                        className="nav-link btn btn-primary radius-20 ml-3 mr-3 text-tranform-none"
                        href="https://campsoberfest.com"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Redirect to campsoberfest.com"
                        target="_blank"
                      >
                        CAMP SOBER FEST
                      </Nav.Link>
                    </Nav.Item>
                  }
                  <Nav.Item as="li">
                    <Nav.Link
                      className="nav-link py-0 pl-0 pr-3 text-tranform-none"
                      onClick={() => this.setState({ open: true })}
                    >
                      <img
                        src={
                          hasLocationPermission
                            ? locationImage
                            : locationDisableImage
                        }
                        className="location-icon"
                        alt="Location"
                      />

                      {/* If location disabled use below image */}
                      {/* <img src={locationDisableImage} className="location-icon" alt="Location" /> */}
                    </Nav.Link>
                  </Nav.Item>
                  {!this.props.hasAuthToken && (
                    <Nav.Item as="li">
                      <Nav.Link
                        className="nav-link btn btn-outline radius-20 bg-light  signin-btn"
                        onClick={() => this.toggleSignInModal(true)}
                      >
                        Sign in
                      </Nav.Link>
                      <ModalComponent
                        component={Login}
                        show={
                          this.state.signInModalShow ||
                          this.context.isShowLogin()
                        }
                        onHide={() => this.toggleSignInModal(false)}
                        onRegistration={() =>
                          this.toggleRegistrationModal(true)
                        }
                        className="authentication"
                        backdrop="static"
                      />
                    </Nav.Item>
                  )}
                  {!this.props.hasAuthToken && (
                    <Nav.Item>
                      <Nav.Link
                        className="nav-link btn btn-primary radius-20 ml-3 text-tranform-none"
                        as="li"
                        onClick={() => this.toggleRegistrationModal(true)}
                        style={{ display: "inline-block" }}
                      >
                        Register
                      </Nav.Link>
                      <ModalComponent
                        component={Registration}
                        show={this.state.registrationModalShow}
                        onHide={() => {
                          this.toggleRegistrationModal(false);
                        }}
                        onSignUp={() => {
                          this.props.history.push({
                            pathname: `profile`,
                            state: { isFromSignUp: true },
                          });
                        }}
                        onSignIn={() => this.toggleSignInModal(true)}
                        className="authentication"
                        backdrop="static"
                      />
                    </Nav.Item>
                  )}

                  {this.props.hasAuthToken && (
                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        disabled={this.unReadMessageCount() == 0}
                        className="nav-link  text-tranform-none px-0"
                        to="/chats"
                      >
                        <i className="fas fa-bell fa-lg cursor-pointer "></i>
                        {this.unReadMessageCount() != 0 && (
                          <sup style={{ verticalAlign: "top" }}>
                            <span className="badge btn-primary">
                              {this.unReadMessageCount()}
                            </span>
                          </sup>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item className="ml-3" as="li">
                    <NavDropdown
                      id="basic-nav-dropdown"
                      title={
                        <div className="dropdown-toggle">
                          <img src={menuImage} alt="" />
                        </div>
                      }
                    >
                      {this.props.hasAuthToken && (
                        <>
                          {this.props.isHost && (
                            <NavDropdown.Item as={Link} to="/profile">
                              Profile
                            </NavDropdown.Item>
                          )}
                          <NavDropdown.Item as={Link} to="/scheduled-tours">
                            Scheduled Tours
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/chats">
                            Chats{"     "}
                            {this.unReadMessageCount() != 0 && (
                              <span className="badge btn-primary">
                                {this.unReadMessageCount()}
                              </span>
                            )}
                          </NavDropdown.Item>

                          {this.props.isHost && (
                            <NavDropdown.Item as={Link} to="/dashboard">
                              Dashboard
                            </NavDropdown.Item>
                          )}

                          <NavDropdown.Item as={Link} to="/settings">
                            Settings
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => this.onLogout()}>
                            Logout
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                        </>
                      )}

                      <NavDropdown.Item as={Link} to="/about-us">
                        About
                      </NavDropdown.Item>

                      <NavDropdown.Item as={Link} to="/terms-of-service">
                        Terms of Services
                      </NavDropdown.Item>

                      <NavDropdown.Item to="/help-and-support" as={Link}>
                        Help and Support
                      </NavDropdown.Item>

                      {/* {!this.props.isHost && <NavDropdown.Item
                          href={`${process.env.REACT_APP_BASE_PATH}/faq`}
                        >
                          FAQs
                        </NavDropdown.Item>} */}

                      <NavDropdown.Item href="https://www.tasterecovery.com/resources/blog/">
                        Resources
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.tasterecovery.com/resources/recovery-resources/">
                        Recovery Resources
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/contact-support">
                        Contact Us
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </nav>
        </header>

        {this.state.open && (
          <div
            className="modal upgrade-plan cancel-subscription-modal fade show"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="upgrade-plan-content">
                  <h3>
                    Location Access{" "}
                    {hasLocationPermission ? "Enabled" : "Disabled"}{" "}
                  </h3>
                  <span className="loading-msg">
                    Location services are currently{" "}
                    {hasLocationPermission ? "enabled" : "disabled"}. You can
                    manage location settings in the site settings.
                  </span>
                  <img
                    src={
                      hasLocationPermission
                        ? locationModalImage
                        : locationDisableModalImage
                    }
                    className="modal-image"
                    alt="Location"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="modal-btn"
                    onClick={() => this.setState({ open: false })}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal-btn confirm-btn"
                    onClick={() => this.setState({ open: false })}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    ({ chat, auth, home }) => ({
      hasAuthToken: auth.authToken,
      forgotPasswordSuccess: auth.forgotPassword,
      chats: chat.chats,
      isHost: auth.isHost,
      isShow: home.isShow,
      user: auth?.user,
    }),
    (dispatch) => {
      return {
        ...bindActionCreators({ ...auth.authAction, ...chatActions }, dispatch),
      };
    }
  )(HeaderComponent)
);
